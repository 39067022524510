import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    pathClient: '',
    pathAdmin: '',
    pathGerente: '',
    title: 'Menú',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    value: 0,
    submenu: []
  },
  {
    path: '',
    pathClient: '',
    pathAdmin: '',
    pathGerente: '',
    title: 'Configuración',
    icon: 'mdi mdi-settings',
    class: 'has-arrow',
    extralink: false,
    value: 0,
    submenu: [
      {
        path: '',
        pathClient: '',
        pathAdmin: '',
        pathGerente: '',
        title: 'Alta usuarios',
        icon: 'mdi mdi-account',
        class: '',
        extralink: false,
        value: 1,
        submenu: []
      },
      {
        path: '',
        pathClient: '',
        pathAdmin: '/publishDocument',
        pathGerente: '/publishDocument',
        title: 'Publicar documento',
        icon: 'mdi mdi-folder-upload',
        class: '',
        extralink: false,
        value: 2,
        submenu: []
      },
      {
        path: '',
        pathClient: '',
        pathAdmin: '',
        pathGerente: '',
        title: 'Consultas',
        icon: 'mdi mdi-account-search',
        class: 'has-arrow',
        extralink: false,
        value: 3,
        submenu: [
          {
            path: '',
            pathClient: '',
            pathAdmin: '/reportes',
            pathGerente: '/reportes',
            title: 'Dashboards',
            icon: 'mdi  mdi-chart-pie',
            class: '',
            extralink: false,
            value: 1,
            submenu: []
          },
          {
            path: '',
            pathClient: '',
            pathAdmin: '/sucursales-clientes',
            pathGerente: '/sucursales-clientes',
            title: 'Sucursales de clientes',
            icon: 'mdi mdi-factory',
            class: '',
            extralink: false,
            value: 4,
            submenu: []
          }
        ]
      },
      {
        path: '',
        pathClient: '',
        pathAdmin: '/notificaciones',
        pathGerente: '/notificaciones',
        title: 'Notificaciones',
        icon: 'mdi mdi-bell-ring',
        class: '',
        extralink: false,
        value: 2,
        submenu: []
      }
    ]
  },
  {
    path: '',
    pathClient: '',
    pathAdmin: '',
    pathGerente: '',
    title: 'Carpeta operativa',
    icon: 'mdi mdi-folder-multiple',
    class: 'has-arrow',
    extralink: false,
    value: 1,
    submenu: [
      {
        path: '',
        pathAdmin: '/document',
        pathClient: '/customer-document',
        pathGerente: '/document',
        title: 'Documentos oficiales',
        icon: 'mdi mdi-file-document',
        class: '',
        extralink: false,
        value: 1,
        submenu: []
      },
      {
        path: '',
        pathAdmin: '/document',
        pathClient: '/customer-document',
        pathGerente: '/document',
        title: 'Plan de trabajo',
        icon: 'mdi  mdi-calendar-multiple',
        class: '',
        extralink: false,
        value: 2,
        submenu: []
      },
      {
        path: '',
        pathAdmin: '/document',
        pathClient: '/customer-document',
        pathGerente: '/document',
        title: 'Personal operativo',
        icon: 'mdi mdi-account-switch',
        class: '',
        extralink: false,
        value: 3,
        submenu: []
      },
      {
        path: '',
        pathAdmin: '/document',
        pathClient: '/customer-document',
        pathGerente: '/document',
        title: 'Información técnica',
        icon: 'mdi mdi-clipboard-outline',
        class: '',
        value: 4,
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    pathAdmin: '',
    pathClient: '',
    pathGerente: '',
    title: 'Calendario',
    icon: 'mdi mdi-calendar',
    class: 'has-arrow',
    extralink: false,
    value: 2,
    submenu: [
      {
        path: '',
        pathAdmin: '/calendario',
        pathClient: '/calendario',
        pathGerente: '/calendario',
        title: 'Progr. Mensual Servicios',
        icon: 'mdi mdi-calendar',
        class: '',
        extralink: false,
        value: 1,
        submenu: []
      },
      {
        path: '',
        pathAdmin: '/calendario',
        pathClient: '/calendario',
        pathGerente: '/calendario',
        title: 'Progr. Anual Servicios',
        icon: 'mdi mdi-calendar-multiple-check',
        class: '',
        extralink: false,
        value: 2,
        submenu: []
      },
      {
        path: '',
        pathAdmin: '/calendario',
        pathClient: '/calendario',
        pathGerente: '/calendario',
        title: 'Eventos',
        icon: 'mdi mdi-calendar-text',
        class: '',
        extralink: false,
        value: 2,
        submenu: []
      }
    ]
  },
  {
    path: '',
    pathAdmin: '',
    pathClient: '',
    pathGerente: '',
    title: 'Reportes',
    icon: 'mdi mdi-file-chart',
    class: 'has-arrow',
    extralink: false,
    value: 3,
    submenu: [
      {
        path: '',
        pathAdmin: '/document',
        pathClient: '/customer-document',
        pathGerente: '/document',
        title: 'Análisis de tendencia',
        icon: 'mdi mdi-chart-line',
        class: '',
        extralink: false,
        value: 1,
        submenu: []
      },
      {
        path: '',
        pathAdmin: '/document',
        pathClient: '/customer-document',
        pathGerente: '/document',
        title: 'Monitoreo de roedores',
        icon: 'mdi mdi-clipboard-text',
        class: '',
        extralink: false,
        value: 2,
        submenu: []
      },
      {
        path: '',
        pathAdmin: '/document',
        pathClient: '/customer-document',
        pathGerente: '/document',
        title: 'Inspección de plaguicidas',
        icon: 'mdi mdi-clipboard-text',
        class: '',
        extralink: false,
        value: 3,
        submenu: []
      },
      {
        path: '',
        pathAdmin: '/document',
        pathClient: '/customer-document',
        pathGerente: '/document',
        title: 'Monitoreo de insectos',
        icon: 'mdi mdi-clipboard-text',
        class: '',
        extralink: false,
        value: 4,
        submenu: []
      }
    ]
  },
  {
    path: '',
    pathAdmin: '',
    pathClient: '',
    pathGerente: '',
    title: 'Certificados',
    icon: 'mdi mdi-book',
    class: 'has-arrow',
    extralink: false,
    value: 4,
    submenu: [
      {
        path: '',
        pathAdmin: '/document',
        pathClient: '/customer-document',
        pathGerente: 'document',
        title: 'Servicios',
        icon: 'mdi mdi-clipboard-check',
        class: '',
        extralink: false,
        value: 1,
        submenu: []
      }
    ]
  },
  {
    path: '',
    pathAdmin: '',
    pathClient: '',
    pathGerente: '',
    title: 'Encuestas',
    icon: 'mdi mdi-account-star',
    class: 'has-arrow',
    extralink: false,
    value: 5,
    submenu: [    ]
  }
];
