import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'; 
import 'rxjs/add/operator/map' 
@Injectable({
  providedIn: 'root'
})
export class DashboarddocumentsService {

  constructor(private httpClient : HttpClient) { }

  getReportForDocuments(language_id, start_date, end_date) {
    // console.log('Guardar-->',filesUpload);
    return this.httpClient.get('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/dashboards/data/'
    + start_date + '/' + end_date, 
    {headers: {'language_id':language_id}}).map(data => data);  
  }

  getReportForDocumentsDetail(language_id, start_date, end_date, path_id, zone_id, route_id, status_id) {
    // console.log('Guardar-->',filesUpload);
    return this.httpClient.get('http://adec-authorization.us-east-2.elasticbeanstalk.com/v1/ema-system/reports/customers/documents-list/'
                                + start_date + '/' + end_date + '/' + path_id + '/' + zone_id + '/' + route_id + '/'+ status_id, 
                                {headers: {'language_id':language_id}}).map(data => data);  
  }
  
}
