import { Component } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [ './loginstyle.css'],
  providers: [AuthService]
})
export class LoginComponent {
  msg = '';
  staticAlertClosedLogin = true;
  alertLogin:IAlert;
  constructor(private authService: AuthService, private routes: Router) { 
    this.alertLogin = {
      id: 1,
      type: 'danger',
      message: 'Se requiere seleccionar todos los campos..'
    };
  }

  loginform = true;
  recoverform = false;
  ennableInputs = false;
  ennableSpinner = true;
  
  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }
  check(uname: string, p: string) {
    //Validar
    this.ennableInputs = true; 
    this.ennableSpinner = false;
    this.staticAlertClosedLogin = true;
    if(uname.length > 0 && p.length > 0) {
          const output = this.authService.login(uname, p).subscribe( (res: any) => {
            this.msg = "";
            if (res.code  != '200') {
             
              this.staticAlertClosedLogin = false;
              this.alertLogin.type = 'danger';
              this.alertLogin.message = 'Error de login.';
              this.resetLogin();
              this.ennableSpinner = true;
              this.ennableInputs = false;
            }
          },
          (err: any) => {
            this.staticAlertClosedLogin = false;
            this.alertLogin.type = 'danger';
            this.alertLogin.message = 'Usuario o contraseña incorrecto.';
            this.ennableSpinner = true;
            this.ennableInputs = false;
            this.resetLogin();
          }
        );
    } else {
      this.staticAlertClosedLogin = false;
      this.alertLogin.type = 'danger';
      this.alertLogin.message = 'Se requiere usuario y contraseña.';
      this.resetLogin();
      this.ennableSpinner = true;
      this.ennableInputs = false;
    }
  }

  resetLogin(){
    setTimeout(() => {this.staticAlertClosedLogin = true}, 1500);
  }
}


export interface IAlert {
  id: number;
  type: string;
  message: string;
}